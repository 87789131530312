<template>
  <div class="mx-auto max-w-2xl pt-8 lg:pt-24">
    <h1 class="mb-8 lg:mb-14 text-left">Manage Your Account</h1>

    <form
      class="mb-11"
      @submit.prevent="onSubmitInfo"
    >
      <div class="row pb-3 border-b border-grey-mediumLight mb-9">
        <p class="m-0 font-medium text-brand-dark text-2xl">Personal Information</p>
      </div>

      <div class="row flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mb-5">
        <form-field-input
          v-model.trim="userData.name"
          class="flex-1"
          size="medium"
          field-label="Name *"
          placeholder="Enter Full Name"
          :validation-class="{invalid: $v.userData.name.$error, valid: !$v.userData.name.$invalid}"
          @blur="$v.userData.name.$touch()"
        >
          <template #helper v-if="$v.userData.name.$error">
            <span
              v-if="!$v.userData.name.required"
              class="invalid"
            >
              First Name can't be empty
            </span>
          </template>
        </form-field-input>
      </div>

      <div class="row flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mb-5">
        <form-field-input
          v-model.trim="userData.email"
          class="flex-1"
          size="medium"
          field-label="E-mail Address *"
          placeholder="example@dsm.com"
          :validation-class="{invalid: $v.userData.email.$error, valid: !$v.userData.email.$invalid}"
          @blur="$v.userData.email.$touch()"
        >
          <template #helper v-if="$v.userData.email.$error">
            <span
              v-if="!$v.userData.email.required"
              class="invalid"
            >
              Email can't be empty
            </span>

            <span
              v-if="!$v.userData.email.email"
              class="invalid"
            >
              Email isn't valid
            </span>
          </template>
        </form-field-input>

        <form-field-input
          v-model.trim="userData.phoneNumber"
          class="flex-1"
          size="medium"
          field-label="Phone Number *"
          placeholder="Enter Phone Number"
          :validation-class="{invalid: $v.userData.phoneNumber.$error, valid: !$v.userData.phoneNumber.$invalid}"
          @blur="$v.userData.phoneNumber.$touch()"
        >
          <template #helper v-if="$v.userData.phoneNumber.$error">
            <span
              v-if="!$v.userData.phoneNumber.required"
              class="invalid"
            >
              Phone Number can't be empty
            </span>
          </template>
        </form-field-input>
      </div>

      <div class="row flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mb-11">
        <form-field-input
          v-model.trim="userData.company"
          class="flex-1"
          size="medium"
          field-label="Company Name *"
          placeholder="Enter Company Name"
          :validation-class="{invalid: $v.userData.company.$error, valid: !$v.userData.company.$invalid}"
          @blur="$v.userData.company.$touch()"
        >
          <template #helper v-if="$v.userData.company.$error">
            <span
              v-if="!$v.userData.company.required"
              class="invalid"
            >
              Company can't be empty
            </span>
          </template>
        </form-field-input>

        <form-field-select
          v-model="userData.country"
          class="flex-1"
          field-label="Country *"
          option-label="country"
          track-by="code"
          disabled-option-value="Select country"
          :validation-class="{invalid: $v.userData.country.$error, valid: !$v.userData.country.$invalid}"
          :options="countries"
        >
          <template #helper v-if="$v.userData.country.$error">
            <span
              v-if="!$v.userData.country.required"
              class="invalid"
            >
              Select Country
            </span>
          </template>
        </form-field-select>
      </div>

      <div class="row flex justify-end">
        <button type="submit" class="btn-success">Update Account</button>
      </div>
    </form>

    <form
      class="mb-11"
      @submit.prevent="onSubmitPassword"
    >
      <div class="row pb-3 border-b border-grey-mediumLight mb-9">
        <p class="m-0 font-medium text-brand-dark text-2xl">Update Password</p>
      </div>

      <div class="row flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mb-5">
        <form-field-input
          v-model.trim="userData.password"
          class="flex-1"
          size="medium"
          type="password"
          field-label="New Password *"
          placeholder="Enter Password"
          :validation-class="{invalid: $v.userData.password.$error, valid: !$v.userData.password.$invalid}"
          @blur="$v.userData.password.$touch()"
        >
          <template #helper v-if="$v.userData.password.$error">
            <span
              v-if="!$v.userData.password.required"
              class="invalid"
            >
              Password can't be empty
            </span>

            <span
              v-if="!$v.userData.password.minLength"
              class="invalid"
            >
                min Length is 6
            </span>
          </template>
        </form-field-input>

        <form-field-input
          v-model.trim="userData.password_confirmation"
          class="flex-1"
          size="medium"
          type="password"
          field-label="New Password *"
          placeholder="Confirm Password"
          :validation-class="{invalid: $v.userData.password_confirmation.$error, valid: $v.userData.password_confirmation.sameAsPassword && !$v.userData.password.$invalid}"
          @blur="$v.userData.password_confirmation.$touch()"
        >
          <template #helper v-if="$v.userData.password_confirmation.$error">
            <span
              v-if="!$v.userData.password_confirmation.required"
              class="invalid"
            >
              Passwords must be identical
            </span>

            <span
              v-if="!$v.userData.password_confirmation.minLength && $v.userData.password_confirmation.sameAsPassword"
              class="invalid"
            >
              min Length is 6
            </span>
          </template>
        </form-field-input>
      </div>

      <div class="row flex justify-end">
        <button type="submit" class="btn-success">Update Password</button>
      </div>
    </form>

    <div class="row pt-3 border-t border-grey-mediumLight mb-9 flex justify-between font-semibold">
      <button
        class="btn-danger remove"
        @click="onClearUserData"
      >
        <span class="icon" v-html="icons.broom"></span>
        Erase my Data
      </button>

      <confirmation-delete
        delete-title="Delete my Data"
        :is-open="isOpen"
        @onClose="onClose"
        @onDelete="onDelete"
      >
        <h3 class="sub-title text-2xl m-0 text-left">
          You are about to delete
          <small>Are you sure you want to erase all the data that have been recorder in your account?</small>
        </h3>
      </confirmation-delete>

      <button
        class="btn-danger remove"
        @click="onConfirmDeleteAccount"
      >
        <span class="icon" v-html="icons.trash"></span>

        Delete my Account
      </button>

      <confirmation-delete
        delete-title="Delete my Account"
        :is-open="isOpenAccount"
        @onClose="onCloseAccount"
        @onDelete="onDeleteAccount"
      >
        <h3 class="sub-title text-2xl m-0 text-left">
          You are about to delete account
        </h3>
      </confirmation-delete>
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import ConfirmationDelete from '@/components/modals/ConfirmationDelete';
import FormFieldSelect from '@/components/form/FormFieldSelect';
import {validationMixin} from 'vuelidate';
import {mapState, mapActions, mapMutations} from 'vuex';
import {required, minLength, email, sameAs} from 'vuelidate/lib/validators';

export default {
  name: 'MyAccount',

  components: {
    FormFieldInput,
    FormFieldSelect,
    ConfirmationDelete
  },

  mixins: [validationMixin],

  validations: {
    userData: {
      name: {
        required,
      },

      email: {
        required,
        email,
      },

      phoneNumber: {
        required
      },

      company: {},

      country: {},

      password: {
        required,
        minLength: minLength(6),
      },

      password_confirmation: {
        sameAsPassword: sameAs('password'),
      }
    }
  },

  data: () => ({
    userData: {
      name: '',
      email: '',
      phoneNumber: '',
      company: '',
      country: '',
      password: '',
      password_confirmation: ''
    },
    isOpen: false,
    isOpenAccount: false,
  }),

  mounted() {
    if (!this.countries.length) {
      this.getCountries();
    }

    this.setUserData(this.user)

    this.$store.commit('setNavigation');
  },

  methods: {
    ...mapActions('countries', [
      'fetchCountries',
    ]),

    ...mapMutations({
      setUser: 'auth/setUser'
    }),

    invalidValidation(include, field) {
      for (const param in this.$v.userData.$params) {
        if (Object.prototype.hasOwnProperty.call(this.$v.userData.$params, param)) {
          if (include ? param.toLowerCase().includes(field) : !param.toLowerCase().includes(field)) {
            continue
          }

          this.$v.userData[param].$touch();
        }
      }
    },

    onSubmitInfo() {
      // if (this.$v.$invalid) {
      //   console.log('here');
      //   this.invalidValidation(true, 'password')
      //   return;
      // }

      const formData = {
        name: this.userData.name,
        email: this.userData.email,
        phone: this.userData.phoneNumber,
        company: this.userData.company,
        country: this.userData.country
      };

      // call API
      this.$api.post(`/users/${this.user.id}`, formData).then(res => {
        console.log(res)
        this.setUser(res.data)
        this.$toast.open({
          message: `Account information saved!`
        })
      }).catch((error) => {
        const {message} = error.response && error.response.data ? error.response.data : {}
        console.log(message);
      })
    },

    onSubmitPassword() {
      if (this.$v.$invalid) {
        this.invalidValidation(false, 'password')
        return;
      }

      const formData = {
        email: this.userData.email,
        password: this.userData.password,
        password_confirmation: this.userData.password_confirmation
      };

      this.$api.post(`/users/${this.user.id}/reset-password`, formData).then(res => {
        console.log(res)
        this.setUser(res.data)
        this.$toast.open({
          message: `Password has been updated!`
        })
      }).catch((error) => {
        const {message} = error.response && error.response.data ? error.response.data : {}
        console.log(message);
      })
      return formData;
    },

    getCountries() {
      return this.fetchCountries();
    },

    onClose() {
      this.isOpen = false;
    },

    onDelete() {
      this.$api.delete('/users/delete-user-data')
          .then(() => {
            for (const data in this.userData) {
              if (Object.prototype.hasOwnProperty.call(this.userData, data)) {
                this.userData[data] = ''
              }
            }

            this.$v.$reset()
          })
          .catch((error) => {
            this.$toast.open({
              type: 'error',
              message: error.message
            })
          })
          .finally(() => {
            this.isOpen = false;
          });
    },

    onClearUserData() {
      this.isOpen = true;
    },

    onConfirmDeleteAccount() {
      this.isOpenAccount = true;
    },

    onCloseAccount() {
      this.isOpenAccount = false
    },

    onDeleteAccount() {
      this.$api.delete('/users/delete')
          .then(() => {
            this.$store.commit('auth/SET_USER_DATA', {
              isAuth: false,
              userData: null
            });
            this.$router.push({name: 'login'});
          })
          .catch((error) => {
            this.$toast.open({
              type: 'error',
              message: error.message
            })
          })
          .finally(() => {
            this.isOpenAccount = false;
          });
    },

    setUserData(val) {
      this.userData.name = val.name
      this.userData.email = val.email
      this.userData.phoneNumber = val.phone
      this.userData.company = val.company
      this.userData.country = val.country
    }
  },

  computed: {
    ...mapState({
      countries: state => state.countries.countries,
      user: state => state.auth.user
    }),
  },

  watch: {
    'user': {
      handler(val) {
        this.setUserData(val)
      },
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
