<template>
  <modal
    ref="modal"
    :isOpen="isOpen"
    @close="onModalClose"
  >
    <template #title>
      <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
        <div class="attention">
          <span class="icon" v-html="icons.attention"></span>
        </div>
          <slot />
      </div>
    </template>

    <template #button>
      <button class="remove" @click="onDeleteAction">
        {{ deleteTitle }}
      </button>
    </template>
  </modal>
</template>

<script>
/* eslint indent: 0 */
/* eslint no-unused-vars: 0 */
/* eslint prefer-const: 0 */
/* eslint quotes: 0 */
/* eslint no-var: 0 */
import Modal from '@/components/Modal';

export default {
  name: 'ConfirmationDelete',

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    deleteTitle: {
      type: String,
      default: 'Delete'
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
  },
  components: {
    Modal
  },
  methods: {
    onModalClose() {
      this.$emit('onClose');
    },
    onDeleteAction() {
      this.$emit('onDelete');
    }
  }
};
</script>

<style scoped>
input:checked ~ .wrapper {
  @apply bg-brand-semiMedium
}

input:checked ~ .dot {
  transform: translateX(100%);
  @apply bg-white
}
</style>
